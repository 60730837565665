.swiper-wrapper {
  width: 75vw !important;
  max-width: 30rem !important;
  // height: 120%;
  //   padding: 3rem 5rem !important;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  left: 0 !important;
}
.swiper-button-next {
  right: 0 !important;
}
.swiper-button-next,
.swiper-button-prev {
  border-radius: 15px;
  transition: 0.2s;
  &:active {
    transition: 0.2s;
    background-color: var(--text-light);
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}
