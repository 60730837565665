/*---------------------------------------
  # General class
-------------------------------------------*/
.bg-parallax {
  background-color: transparent !important;
}

.left-right-grad {
  background: linear-gradient(90deg, #043b6a 42.85%, rgba(4, 59, 106, 0) 100%);
}

.top-bot-grad {
  background: linear-gradient(180deg, #043b6a 0%, rgba(4, 59, 106, 0.61) 100%);
}

.bot-top-grad {
  background: linear-gradient(0deg, #043b6a 0%, rgba(4, 59, 106, 0.61) 100%);
}

.bot-top-grad-solid {
  background: linear-gradient(180deg, #043b6a 0%, #001a31 100%);
}

.solid {
  background-color: #043b6a;
}

.section-container,
.hero {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

.color-bg-dark {
  background-color: $dark;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $white-smoke !important;
  }
}

.color-bg-dark-gradient {
  @extend .color-bg-dark;
  background-color: transparent;
  background: linear-gradient(180deg, #0f4c81 -2.78%, #0a375e 100%);
}
.color-bg-light {
  background-color: $white;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $secondary !important;
  }
}
/*---------------------------------------
  # Sections
    -----------------------------------------*/
.section {
  padding: $general-x-padding-lg 5rem;
  overflow: hidden;
  align-content: center;
}

.section {
  h2 {
    margin-bottom: 2rem;
  }
}
/*----------------------------------------------
  # HeroSection
------------------------------------------------*/
// .hero {
//   min-height: 100vh;
// }

.hero h2 {
  margin: 15px 0 0 0;
}

@media (max-width: 991px) {
  .hero {
    padding: 120px 30px 60px 20px;
    align-content: center;
    justify-content: center;
    text-align: center;
    #home-hero-logo {
      margin: auto;
    }
  }
  .hero .hero-img {
    text-align: center;
    margin-top: 80px;
  }
  .hero .hero-img img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 32px;
  }
  .hero .hero-img img {
    width: 100%;
  }
}
/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about p {
  margin: 15px 0 30px 0;
  line-height: 24px;
}

.about .btn-read-more {
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: $primary;
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.about .btn-read-more span {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.about .btn-read-more i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.about .btn-read-more:hover i {
  transform: translateX(5px);
}
/*------------------------------------------------
  # operation
  --------------------------------------------------*/

.operation h2 {
  padding: 0 15px;
}
.operation .feture-tabs {
  margin-top: 120px;
}

.operation .feture-tabs h3 {
  color: $dark;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .operation .feture-tabs h3 {
    font-size: 28px;
  }
  .operation .feature-box i {
    font-size: 36px;
  }
}

.operation .feture-tabs .nav-pills {
  border-bottom: 1px solid #eee;
}

.operation .feture-tabs .nav-link {
  background: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: $dark;
  padding: 12px 0;
  margin-right: 25px;
  margin-bottom: -2px;
  border-radius: 0;
}

.operation .feture-tabs .nav-link.active {
  color: $primary;
  border-bottom: 3px solid $primary;
}

.operation .feture-tabs .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: $dark;
}

.operation .feture-tabs .tab-content i {
  font-size: 24px;
  line-height: 0;
  margin-right: 8px;
  color: $primary;
}

.operation .feature-icons {
  margin-top: 120px;
}

.operation .feature-icons h3 {
  color: $dark;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .operation .feature-icons h3 {
    font-size: 28px;
  }
}

.operation .feature-icons .content .icon-box {
  display: flex;
}

.operation .feature-icons .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: $dark;
}

.operation .feature-icons .content .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: #0245bc;
  margin-right: 15px;
}
.operation .feature-icons .content .icon-box p {
  font-size: 15px;
  color: #848484;
}
.operation .rounded-number {
  padding: 4px;
  line-height: 0;
  width: 40px;
  height: 40px;
  background-color: $primary;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 10px;
  transition: 0.3s;
}

.operation .rounded-number:hover i {
  background: $primary;
  color: $white;
}

.hero-buttons {
  display: flex;
  gap: 8px;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* responsive text align */
.responsive-text-align {
  @media (max-width: 991px) {
    text-align: center !important;
  }
  @media (min-width: 992px) {
    text-align: left !important;
  }
}
