.faq-category {
  cursor: pointer;
  text-align: center;
  padding: 10px 15px;
  font-weight: 700;
  transition: 0.3s ease all;
}

.faq-category:hover {
  div.d-flex {
    box-shadow: 0 0 13px 0 rgba(185, 185, 185, 0.25);
  }
}

.active-category {
  border: 2px solid $primary;
}
