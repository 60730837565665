// Dropzone
.dropzone {
  width: 100%;
  min-height: 200px;
  padding: 20px;
  border: 2px dashed $secondary;
  background: $light;

  h4,
  i {
    color: #2a568efc;
  }
  h6 {
    color: #7b8a9a;
  }
}

.aside-dropzone {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.thumb-dropzone {
  display: inline-flex;
  width: 100%;
  padding: 5px;
  margin-bottom: 8px;
  margin-right: 8px;

  .thumbInner-dropzone {
    display: flex;
    min-width: 0;
    overflow: hidden;
    background-color: #e7f4ff;
    padding: 5px 5px 0px 5px;
    color: #2d588f;
  }
}
