$bg-colors: (
  card-blue: #70d0f2,
  card-yellow: #f7f08d,
  card-green: #9df78d,
  card-red: #f78d98,
);

$icon-colors: (
  card-blue: #27a0cd,
  card-yellow: #bdb31d,
  card-green: #35bd1d,
  card-red: #bd1d2d,
);

@function bg-color($key) {
  @return map-get($bg-colors, $key);
}

@function icon-color($key) {
  @return map-get($icon-colors, $key);
}
$icon-card-size: 5.375rem;
$icon-size: 3rem;

.icon-card {
  width: $icon-card-size;
  height: $icon-card-size;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $icon-size;
  margin-right: 1rem;
}

@each $color, $value in $bg-colors {
  .icon-#{$color} {
    @extend .icon-card;
    background-color: bg-color($color);
  }
}

@each $color, $value in $icon-colors {
  .icon-#{$color} {
    @extend .icon-card;
    color: icon-color($color);
  }
}
