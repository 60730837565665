//For single inputs
.general_input {
  border-color: #5668d1;
  border-radius: 0 !important;
  height: 3.175rem;
}
// .general_input_error {
//   border-color: #dc3545 !important;
// }
//For react select
.css-1s2u09g-control,
.css-1pahdxg-control {
  border-color: #5668d1 !important;
  border-radius: 0 !important;
  min-height: 3.175rem !important;
}
