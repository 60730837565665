.basic-info-wrapper {
  .profile-pic {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 200px;
  }
  .show-more-btn-container {
    @media (min-width: 1200px) {
      padding: 0 1rem;
    }
  }
  .info-container {
    display: grid;
    @media (min-width: 1200px) {
      grid-template-columns: 1fr;
      padding: 0 1rem;
    }
    @media (max-width: 1199px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 550px) {
      grid-template-columns: 1fr;
    }
    .info-field {
      word-break: break-all;
      white-space: pre-wrap;
      margin-bottom: 1rem;
    }
  }
  .patient-name {
    @media (min-width: 1200px) {
      text-align: center;
    }
    @media (max-width: 1199px) {
      text-align: start;
    }
    @media (max-width: 767px) {
      text-align: center;
    }
  }
}
