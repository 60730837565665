/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

body {
  background: $content-bg;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: PoppinsBold;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Rubik;
  src: url("../fonts/Rubik/Rubik-Regular.ttf");
}

@font-face {
  font-family: RubikMedium;
  src: url("../fonts/Rubik/Rubik-Medium.ttf");
}

h1,
h2,
h3,
h4,
h5,
h6,
th {
  font-family: Poppins !important;
  b {
    font-family: PoppinsBold !important;
  }
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 1.7em;
  }
  h2 {
    font-size: 1.3em;
  }
  h3 {
    font-size: 1em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }
}

p,
a,
.btn,
.nav,
input,
span,
small,
label,
tr,
b,
li,
.fade .alert .alert-danger {
  font-family: Rubik !important;
}

button {
  font-family: RubikMedium !important;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  padding-top: $navbar-height;
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    min-height: 100vh;
    padding-top: 0;
    .main-panel {
      width: 100%;
      transition: none;
      .content-wrapper {
        padding: 0;
        > div {
          height: 100%;
        }
      }
    }
  }
}

.main-panel {
  transition: width $action-transition-duration
      $action-transition-timing-function,
    margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: 2.68rem 2.5rem;
  width: 100%;
  @include flex-grow(1);
  @media (max-width: 767px) {
    padding: 1.5rem 1.5rem;
  }
}

.container-scroller {
  overflow: hidden;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-family: "Roboto", sans-serif;
  font-weight: $font-weight-light;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;
  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}
.page-title {
  color: $black;
  font-size: 1.125rem;
  margin-bottom: 0;
  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    i {
      font-size: 0.9375rem;
      line-height: 36px;
    }
  }
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-header p {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
  color: $dark;
}

@media (max-width: 768px) {
  .section-header p {
    font-size: 28px;
    line-height: 32px;
  }
}

.color-primary {
  color: $primary !important;
}

.color-secondary {
  color: $secondary !important;
}

.color-dark {
  color: $dark !important;
}

.color-warning {
  color: $warning !important;
}

.color-danger {
  color: $danger !important;
}

.color-info {
  color: $info !important;
}

.color-success {
  color: $success !important;
}
