/* Auth */

.auth {
  .auth-logo {
    margin: 1rem 0;
  }
  height: 100%;
  background-color: white;
  .login-half-bg {
    @media (max-width: 991px) {
      width: 0 !important;
      height: 0 !important;
    }
    background: url("../../../images/login/Login-amico.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .register-half-bg {
    @media (max-width: 991px) {
      width: 0 !important;
      height: 0 !important;
    }
    background: url("../../../images/contact/form.svg");
    background-size: contain;
    background-origin: content-box;
    background-position: center;
    background-repeat: no-repeat;
    padding: 2rem calc(4vw + 1rem);
  }
  .forgot-password-half-bg {
    @media (max-width: 991px) {
      width: 0 !important;
      height: 0 !important;
    }
    background: url("../../../images/login/undraw_forgot_password_gi2d.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.lock-full-bg {
    background: url("https://via.placeholder.com/1440x800");
    background-size: cover;
    background-position: center;
  }
  .lock-profile-img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
  }
  .auth-form-light {
    background: $white;
    select {
      color: $input-placeholder-color;
    }
    .input-group {
      .form-control {
        &:focus,
        &:active {
          border-color: $border-color;
        }
      }
    }
  }
  .auth-form-transparent {
    background: transparent;
    .form-control,
    .input-group-text {
      border-color: theme-color(secondary);
      &:focus,
      &:active {
        border-color: theme-color(secondary);
      }
    }
    select {
      outline-color: theme-color(secondary);
    }
  }
  &.auth-img-bg {
    padding: 0;
    .auth-form-transparent {
      @media (min-width: 768px) {
        width: 55%;
        margin: auto;
      }
    }
  }
  .brand-logo {
    margin-bottom: 2rem;
    img {
      width: 150px;
    }
  }
  form {
    .form-group {
      margin-bottom: 1.5rem;
      label {
        font-size: 0.8125rem;
      }
      .form-control {
        background: transparent;
        border-radius: 0;
        font-size: 0.9375rem;
      }
    }
    .auth-form-btn {
      padding: 1rem 3rem;
      line-height: 1.5;
    }
    .auth-link {
      font-size: $default-font-size;
      &:hover {
        color: initial;
      }
    }
  }
}
