/* Dashboard */

.performance-overview-carousel {
  .carousel-item {
    .item {
      &::after {
        content: "";
        width: 1px;
        height: 60px;
        background: theme-color(info);
        position: absolute;
        right: 0;
        top: 25%;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
  .slick-slider {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: -30px;
        width: 1.875rem;
        height: 1.875rem;
        background: #3698fc;
        border-radius: 0.375rem;
      }
      &.slick-prev {
        left: auto;
        right: 2.2rem;
        &:before {
          font-family: Poppins;
          content: "\e64a";
          font-size: 0.875rem;
          color: $white;
          line-height: 1.875rem;
          margin-right: 0.48rem;
        }
      }
      &.slick-next {
        left: auto;
        right: 0rem;
        &:before {
          font-family: Poppins;
          content: "\e649";
          font-size: 0.875rem;
          color: $white;
          line-height: 1.875rem;
          margin-right: 0.48rem;
        }
      }
    }
  }
}

.detailed-reports-carousel {
  .slick-slider {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: -30px;
        width: 1.875rem;
        height: 1.875rem;
        background: #ecedf2;
        border-radius: 0.375rem;
      }
      &.slick-prev {
        left: auto;
        right: 2.2rem;
        &:before {
          font-family: Poppins;
          content: "\e64a";
          font-size: 0.875rem;
          color: #6c7383;
          line-height: 1.875rem;
          margin-right: 0.48rem;
        }
      }
      &.slick-next {
        left: auto;
        right: 0rem;
        &:before {
          font-family: Poppins;
          content: "\e649";
          font-size: 0.875rem;
          color: #6c7383;
          line-height: 1.875rem;
          margin-right: 0.48rem;
        }
      }
    }
  }

  .report-table {
    &::after {
      content: "";
      width: 1px;
      height: 174px;
      background: #b1b1b5;
      position: absolute;
      right: -1rem;
      top: 15%;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

.sales-report-legend {
  ul {
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
    @include display-flex;
    @include align-items(flex-end);
    @include flex-direction(column);
    @include flex-wrap(wrap);
    li {
      @include display-flex;
      @include align-items(center);
      span {
        width: 2.25rem;
        height: 1rem;
        margin-right: 0.4rem;
        display: inline-block;
        font-size: $default-font-size;
      }
      &:nth-child(1) {
        span {
          background: #ffc100;
        }
      }
      &:nth-child(2) {
        span {
          background: #f5a623;
        }
      }
    }
  }
}

.credits-files {
  @media (max-width: 1199px) {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.total-errors {
  text-align: center;
}

.detailed-reports-chart-legend {
  .item {
    .bullet {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
}

.charts-data {
  .progress {
    background: transparent;
    .progress-bar {
      border-radius: 11px;
    }
  }
}
