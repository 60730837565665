div[class^="wizard"],
div[class*="wizard"] {
  flex: 1 0 0;
  //height: 0.15rem;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  //border-top: 4px dotted blue;
}

.wizard-active {
  border-bottom: 4px solid #5668d1;
  color: #5668d1;

  //background-color: #5668d1;
}
.wizard-deactive {
  border-bottom: 4px solid #a5a5a5;
  color: #a5a5a5;

  //background-color: #a5a5a5;
}
