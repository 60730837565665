/* Utilities */

.grid-margin {
  margin-bottom: $card-spacing-y;
}
.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}
.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}
.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}
.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}
.img-lg {
  width: 92px;
  height: 92px;
}
.img-sm {
  width: 43px;
  height: 43px;
}
.img-xs {
  width: 37px;
  height: 37px;
}
.img-ss {
  width: 35px;
  height: 35px;
}
.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);
  > .card {
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}
.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}
.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}
.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}
.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.container-icons {
  & .line {
    height: 15px;
    width: 80%;
    margin: auto;
    background: #1493c3;
    position: relative;
    top: 130px;
    @media (max-width: 992px) {
      display: none;
    }
  }
}

.landing-card-icons {
  text-align: center;

  i {
    color: #1493c3;
    font-size: 140px;
    border: 6px solid #1bcbf2;
    padding: 20px 32px 20px 32px;
    border-radius: 50%;
    display: inline-block;
    background: #021c26;
  }
  p {
    font-size: 20px;
  }
}

.dark-blue {
  background-color: #021c26;
}

.text-dark-blue-1 {
  color: #195369;
}

.text-dark-blue {
  color: #021c26;
}

.text-white {
  color: #fff;
}

.text-light-blue {
  color: #19c7fd;
}

.text-light-blue-2 {
  color: #1493c3;
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-dark-gray {
  color: #373f41;
}

.text-small {
  font-size: 12px;
}

.text-medium {
  font-size: 16px;
  @media (max-width: 575px) {
    font-size: 14px;
  }
}

.text-medium-large {
  font-size: 30px;
  @media (max-width: 767px) {
    font-size: 1.3em;
  }
}

.text-large {
  font-size: 72px;
}

.text-extra-large {
  font-size: 200px;
}

.font-style-normal {
  font-style: normal;
}

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-normal {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.landing-parallax {
  background-image: linear-gradient(
      to bottom,
      rgba(16, 40, 107, 0.473),
      rgb(3, 8, 36)
    ),
    url("../../assets/images/6.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  position: relative;

  & h1 {
    vertical-align: middle;
    position: relative;
    top: 30%;
    color: white;
    text-align: center;
  }

  .btn-container {
    position: relative;
    top: 40%;
  }
}

.landing-comienza {
  background-image: linear-gradient(
      to bottom,
      rgba(24, 40, 75, 0.877),
      rgba(7, 20, 43, 0.966)
    ),
    url("../../assets/images/1.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
}

.image-grouped {
  display: flex;

  .text-avatar,
  img {
    @extend .img-ss;
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $card-bg;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width";

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;
    }
  }

  .text-avatar {
    @extend .bg-inverse-primary;
    color: theme-color(primary);
    font-size: 11px;
    font-weight: 600;
  }
}
.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.aligner-wrapper {
  position: relative;

  .absolute {
    position: absolute;

    &.absolute-center {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }
  }
}
.v-strock-2 {
  width: 3px;
}

.align-center-icon {
  width: auto;
  display: flex;
  align-items: center;
  i {
    margin-left: 0.5rem;
  }
}

a {
  text-decoration: none !important;
}
