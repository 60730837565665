/* Pricing table */

.pricing-table{
  .pricing-card{
    .pricing-card-body{
      padding: 26px;
      @media (max-width: 767px) {
        padding: 1rem;
      }
      .plan-features{
        width: 100%;
        li{
          text-align: center;
          padding: 4px 0px;
          font-weight: 400;
          font-size: $default-font-size;
        }
      }
    }
  }
}