.footer {
  background: $footer-bg;
  color: $footer-color;
  border-top: 1px solid darken($footer-bg, 5%);
  padding: 30px 1rem;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration
    $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration
    $action-transition-timing-function;
  -ms-transition: all $action-transition-duration
    $action-transition-timing-function;
  font-size: calc(#{$default-font-size} - 0.05rem);
  font-family: $type1;
  a {
    color: theme-color(primary);
    font-size: inherit;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}

.landing-footer {
  color: $white-smoke !important;
  a {
    img {
      width: 100px;
      margin: 20px auto;
    }
  }
  span {
    display: block;
    margin: 5px 0px;
    letter-spacing: 0.5em;
    font-size: 12px;
  }
  small {
    display: block;
  }
}
