.accordion-label {
  text-decoration: none;
  font-weight: bold;
}

.accordion-disabled {
  .accordion-label,
  .accordion-status-icon {
    color: gray !important;
  }
  .accordion-status-icon::after {
    content: none;
    background-color: gray !important;
  }
}

.accordion-enabled {
  .accordion-status-icon::after {
    content: "";
    position: absolute;
    height: calc(100% - 16px);
    width: 3px;
    background-color: $secondary;
    right: calc(50% + 3px);
    bottom: -16px;
  }
}

.accordion-enabled:last-child {
  .accordion-status-icon::after {
    content: none;
  }
}

.accordion-header,
.accordion-status-icon {
  padding: 1rem 0;
  color: $secondary;
  & .accordion-label {
    color: $secondary;
  }
}

.accordion-status-icon-container {
  position: relative;
}

.accordion-item-container {
  display: flex;
  width: 100%;
}
