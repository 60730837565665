.hover-card-container {
  position: relative;

  .hover-card-info {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
  }
  &:hover {
    .hover-card-info {
      background-color: rgba(255, 255, 255, 0.9);
      transition: opacity 0.5s;
      opacity: 1;
    }
  }
}
