.global-map-wrapper {
  .global-map-info-container {
    z-index: 999;
    position: fixed;
    width: 300px;
    .global-map-info-card {
      border-radius: 1rem;
      .info-header {
      }
      .info-body {
      }
    }
  }
}
